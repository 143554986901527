import PageContainer from '@components/PageContainer';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import StoreSelector from '@components/StoreSelector';
import { useContext, useEffect, useState } from 'react';
import StoreContext from '../state/Store';
import { useNavigate, useSearchParams } from 'react-router-dom';

const TagScreen = () => {
	const { stores, defaultStore } = useContext(StoreContext);

	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const getStoreIdParam = (searchParams: URLSearchParams) => {
		const storeIdParam = searchParams.get('storeId');
		return storeIdParam && stores.find((s) => s.id === parseInt(storeIdParam)) !== undefined
			? parseInt(storeIdParam)
			: null;
	};
	const storeIdParam = getStoreIdParam(searchParams);

	const [selectedStore, setSelectedStore] = useState(storeIdParam || defaultStore);

	useEffect(() => {
		if (selectedStore === -1) {
			setSearchParams('');
			return;
		}

		setSearchParams(`storeId=${selectedStore}`);
	}, [selectedStore, setSearchParams]);

	return (
		<PageContainer
			title="Taggar"
			extra={
				<>
					<StoreSelector stores={stores} selectedStore={selectedStore} onChange={setSelectedStore} />
					<Button onClick={() => navigate(`create/?storeId=${selectedStore}`)} type="primary" icon={<PlusOutlined />}>
						Skapa tagg
					</Button>
				</>
			}
		></PageContainer>
	);
};

export default TagScreen;
