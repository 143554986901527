import {
	Button,
	Card,
	DatePicker,
	Divider,
	Form,
	Input,
	InputNumber,
	Modal,
	notification,
	Space,
	Upload,
	UploadFile,
} from 'antd';
import { PlusOutlined, SendOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import PageContainer from '../components/PageContainer';
import styles from './styles/ReportTheftScreen.module.scss';
import StoreSelector from '../components/StoreSelector';
import StoreContext from '../state/Store';
import dayjs from 'dayjs';
import TheftContext from '../state/Theft';
import { useNavigate, useSearchParams } from 'react-router-dom';
import r from '../http';
import Theft from '../models/Theft';

const { TextArea } = Input;

const ReportTheftScreen = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { stores, defaultStore } = useContext(StoreContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const getStoreIdParam = (searchParams: URLSearchParams) => {
		const storeIdParam = searchParams.get('storeId');
		return storeIdParam && stores.find((s) => s.id === parseInt(storeIdParam)) !== undefined
			? parseInt(storeIdParam)
			: null;
	};
	const storeIdParam = getStoreIdParam(searchParams);

	const [selectedStore, setSelectedStore] = useState(storeIdParam || defaultStore);
	const { addTheft } = useContext(TheftContext);
	const navigate = useNavigate();

	const [form] = Form.useForm();

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
	};

	const submit = async (formValues: any) => {
		try {
			setIsSubmitting(true);
			const body = new FormData();
			body.append('storeId', selectedStore.toString());
			body.append('userName', formValues.userName);
			body.append('userIdentifier', formValues.userIdentifier);
			body.append(
				'startUTC',
				dayjs(formValues.date.format('YYYY-MM-DD') + ' ' + formValues.startTime.format('HH:mm')).format(
					'YYYY-MM-DD HH:mm'
				)
			);
			body.append(
				'endUTC',
				dayjs(formValues.date.format('YYYY-MM-DD') + ' ' + formValues.endTime.format('HH:mm')).format(
					'YYYY-MM-DD HH:mm'
				)
			);
			body.append('quantityProducts', formValues.quantityProducts);
			body.append('information', formValues.information);

			if (formValues.image) {
				formValues.image.fileList.map((i: UploadFile) => {
					if (i.originFileObj === undefined) return;
					body.append('imageUrls', i.originFileObj);
				});
			}

			if (formValues.video) {
				formValues.video.fileList.map((v: UploadFile) => {
					if (v.originFileObj === undefined) return;
					body.append('videoUrls', v.originFileObj);
				});
			}

			const response = await r.post<Theft>('/admin/thefts', body);
			addTheft(response.data);
			notification.success({
				message: 'Stöld anmäld!',
				placement: 'bottomRight',
				duration: 5,
			});
			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	const confirm = (fromValues: any) => {
		Modal.confirm({
			title: 'Skicka anmälan',
			content: 'Är du säker på att du vill skicka in anmälan?',
			okText: 'Ja, skicka anmälan',
			cancelText: 'Avbryt',
			onOk: () => submit(fromValues),
		});
	};

	useEffect(() => {
		if (selectedStore === -1) {
			setSearchParams('', { replace: true });
			return;
		}

		setSearchParams(`storeId=${selectedStore}`, { replace: true });
	}, [selectedStore, setSearchParams]);

	return (
		<PageContainer back title="Anmäl stöld">
			<Form
				{...layout}
				initialValues={{ store: selectedStore }}
				labelAlign="left"
				form={form}
				size="large"
				onFinish={confirm}
			>
				<Card>
					<Form.Item label="Butik" name="store" rules={[{ required: true, message: 'Vänligen välj en butik' }]}>
						<StoreSelector stores={stores} selectedStore={selectedStore} onChange={setSelectedStore} />
					</Form.Item>
					<Divider />
					<Form.Item label="Namn" name="userName" rules={[{ required: true, message: 'Vänligen ange namn' }]}>
						<Input placeholder="Namn" />
					</Form.Item>
					<Divider />
					<Form.Item
						label="Identifierare"
						name="userIdentifier"
						rules={[{ required: true, message: 'Vänligen ange personnummer eller telefonnummer' }]}
					>
						<Input placeholder="Personnummer/Telefonnummer" />
					</Form.Item>
					<Divider />
					<Form.Item label="Datum" name="date" rules={[{ required: true, message: 'Vänligen ange datum' }]}>
						<DatePicker format={'YYYY-MM-DD'} placeholder="Datum" />
					</Form.Item>
					<Divider />
					<Form.Item label="Starttid" name="startTime" rules={[{ required: true, message: 'Vänligen ange starttid' }]}>
						<DatePicker picker="time" format={'HH:mm'} placeholder="Starttid" />
					</Form.Item>
					<Divider />
					<Form.Item
						label="Sluttid"
						name="endTime"
						rules={[
							{ required: true, message: 'Vänligen ange sluttid' },
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('startTime') < value) {
										return Promise.resolve();
									}
									return Promise.reject(new Error('Vänligen ange giltig sluttid'));
								},
							}),
						]}
					>
						<DatePicker picker="time" format={'HH:mm'} placeholder="Sluttid" />
					</Form.Item>
					<Divider />
					<Form.Item
						label="Antal stulna produkter"
						name="quantityProducts"
						rules={[{ required: true, message: 'Vänligen ange antal stulna produkter' }]}
					>
						<InputNumber placeholder="Antal stulna produkter" addonAfter="st" />
					</Form.Item>
					<Divider />
					<Form.Item label="Information" name="information">
						<TextArea placeholder="Information" />
					</Form.Item>
					<Divider />
					<Form.Item
						label="Bilder"
						name="image"
						valuePropName="files"
						help="Max 3 bilder"
						rules={[{ required: false, message: 'Vänligen välj tre bilder' }]}
					>
						<Upload
							accept="image/*"
							listType="picture-card"
							multiple={true}
							beforeUpload={() => false}
							onRemove={() => {
								requestAnimationFrame(() => {
									form.validateFields(['image']);
								});
								return true;
							}}
							previewFile={async (file) => URL.createObjectURL(file)}
							maxCount={3}
						>
							<div>
								<PlusOutlined />
								<div style={{ marginTop: 8 }}>Välj bild</div>
							</div>
						</Upload>
					</Form.Item>
					<Divider />
					<Form.Item
						label="Video"
						name="video"
						valuePropName="files"
						help="Max 6 videos"
						rules={[{ required: false, message: 'Vänligen välj minst en video' }]}
					>
						<Upload
							accept="video/*"
							listType="picture-card"
							multiple={true}
							beforeUpload={() => false}
							onRemove={() => {
								requestAnimationFrame(() => {
									form.validateFields(['video']);
								});
								return true;
							}}
							previewFile={async (file) => URL.createObjectURL(file)}
							maxCount={6}
						>
							<div>
								<PlusOutlined />
								<div style={{ marginTop: 8 }}>Välj video</div>
							</div>
						</Upload>
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button type="primary" htmlType="submit" loading={isSubmitting}>
								Skicka anmälan <SendOutlined />
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default ReportTheftScreen;
