import styles from './styles/CreateNewsArticleScreen.module.scss';
import { Button, Card, Divider, Form, Input, Space, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import r from '../http';
import PageContainer from '../components/PageContainer';
import NewsArticleContext from '../state/NewsArticle';
import NewsArticle from '../models/NewsArticle';
import StoreContext from '../state/Store';
import StoreSelector from '../components/StoreSelector';
import FormImage from '../components/form/Image';

const CreateNewsArticleScreen = () => {
	const { stores, defaultStore } = useContext(StoreContext);

	const [searchParams, setSearchParams] = useSearchParams();
	const getStoreIdParam = (searchParams: URLSearchParams) => {
		const storeIdParam = searchParams.get('storeId');
		return storeIdParam && stores.find((s) => s.id === parseInt(storeIdParam)) !== undefined
			? parseInt(storeIdParam)
			: null;
	};
	const storeIdParam = getStoreIdParam(searchParams);

	const [selectedStore, setSelectedStore] = useState(storeIdParam || defaultStore);
	const { addNewsArticle } = useContext(NewsArticleContext);
	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [form] = Form.useForm();

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
	};

	const submit = async (form: any) => {
		const body = new FormData();
		body.append('storeId', selectedStore.toString());
		body.append('title', form.title);
		body.append('body', form.body);
		if (form.image[0]) {
			body.append('image', form.image[0].originFileObj);
		}

		try {
			setIsSubmitting(true);
			const response = await r.post<NewsArticle>(`/admin/news`, body);
			addNewsArticle(response.data);
			notification.success({
				message: 'Nyhetsartikel skapad!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	useEffect(() => {
		if (selectedStore === -1) {
			setSearchParams('', { replace: true });
			return;
		}

		setSearchParams(`storeId=${selectedStore}`, { replace: true });
	}, [selectedStore, setSearchParams]);

	return (
		<PageContainer back title="Skapa nyhetsartikel">
			<Form
				initialValues={{ store: selectedStore }}
				{...layout}
				labelAlign="left"
				form={form}
				size="large"
				onFinish={submit}
			>
				<Card>
					<Form.Item label="Butik" name="store" rules={[{ required: true, message: 'Vänligen välj en butik' }]}>
						<StoreSelector stores={stores} selectedStore={selectedStore} onChange={setSelectedStore} />
					</Form.Item>
					<Divider />
					<Form.Item label="Titel" name="title" rules={[{ required: true, message: 'Vänligen ange titel' }]}>
						<Input placeholder="Titel" />
					</Form.Item>
					<Divider />
					<FormImage label="Bild" name="image" rules={[{ required: true, message: 'Vänligen välj en bild' }]} />
					<Divider />
					<Form.Item label="Beskrivning" name="body" rules={[{ required: true, message: 'Vänligen ange beskrivning' }]}>
						<Input.TextArea rows={10} placeholder="Beskrivning" />
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button icon={<PlusOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
								Skapa nyhetsartikel
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default CreateNewsArticleScreen;
