import { Button, Card, Col, Divider, Form, Image, Modal, notification, Row, Spin, Tag, Typography } from 'antd';
import { FileAddOutlined, FileExcelOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../components/PageContainer';
import r from '../http';
import Theft from '../models/Theft';
import StoreContext from '../state/Store';
import TheftContext from '../state/Theft';
import styles from './styles/ViewTheftScreen.module.scss';
import TheftDescription from './TheftDescription';
import useFetch from '@hooks/fetch';

const { Text } = Typography;

const ViewTheftScreen = () => {
	const id = useParams().id || '0';
	const navigate = useNavigate();
	const { thefts, archiveTheft } = useContext(TheftContext);
	const { stores } = useContext(StoreContext);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { data } = useFetch<Theft>(`/admin/thefts/${id}`, undefined, () => {
		notification.error({
			message: `Vi kunde inte hämta stölden. Vänligen försök igen.`,
			placement: 'bottomRight',
			duration: 10,
		});
	});
	const theft = thefts.find((t) => t.id === parseInt(id)) || data;

	const labelLayout = {
		md: 6,
		sm: 24,
		xs: 24,
	};

	const valueLayout = {
		md: 16,
		sm: 24,
		xs: 24,
	};

	const submit = async () => {
		if (!theft) return;
		try {
			setIsSubmitting(true);
			const body = {
				status: theft.status === 0 ? 1 : 0,
			};
			await r.put<Theft>(`/admin/thefts/${theft.id}/status`, body);
			const newTheft = thefts.find((t) => t.id === theft.id);
			if (!newTheft) return;
			newTheft.status = 1;
			archiveTheft(newTheft);
			notification.success({
				message: 'Stöld status uppdaterad!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	const confirm = () => {
		Modal.confirm({
			title: theft?.status === 0 ? 'Arkviera stöld' : 'Avarkviera stöld',
			content: theft?.status === 0 ? 'Vill du arkivera denna stöld?' : 'Vill du avarkivera denna stöld?',
			okText: theft?.status === 0 ? 'Ja, arkivera' : 'Ja, avarkivera',
			cancelText: 'Avbryt',
			onOk: () => submit(),
		});
	};

	return (
		<PageContainer back title="Stöld detaljer">
			<Spin spinning={!theft} tip="Hämtar stöld...">
				<Card key={theft?.id}>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Butik
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{stores.find((s) => s.id === theft?.storeId)?.name}</Text>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Namn
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{theft?.userName}</Text>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Identifierare
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{theft?.userIdentifier}</Text>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Tid
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>
								{dayjs(theft?.start).format('YYYY-MM-DD HH:mm')}
								{' - '}
								{dayjs(theft?.end).format('YYYY-MM-DD HH:mm')}
							</Text>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Antal stulna artiklar
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{theft?.quantityProducts} st</Text>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Information
							</Text>
						</Col>
						<Col {...valueLayout}>{theft ? <TheftDescription content={theft.information} /> : <></>}</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Bilder
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Row>
								{theft?.imageUrls.map((imageSrc) => (
									<div key={imageSrc} style={{ margin: 4 }}>
										<Image height={150} src={imageSrc} />
									</div>
								))}
							</Row>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Videos
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Row>
								{theft?.videoUrls.map((videoSrc, index) => (
									<video style={{ margin: 4 }} key={index} height={150} controls>
										<source src={videoSrc}></source>
									</video>
								))}
							</Row>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Status
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Tag color={theft?.status === 1 ? 'green' : 'red'}>
								{theft?.status === 1 ? 'Arkiverad' : 'Ej arkiverad'}
							</Tag>
						</Col>
					</Row>
				</Card>
				<div className={styles.actions}>
					<Form onFinish={confirm}>
						<Form.Item>
							<Button
								icon={theft?.status === 1 ? <FileExcelOutlined /> : <FileAddOutlined />}
								type="primary"
								danger={theft?.status === 1}
								htmlType="submit"
								loading={isSubmitting}
								size="large"
							>
								{theft?.status === 0 ? 'Arkivera stöld' : 'Avarkivera stöld'}
							</Button>
						</Form.Item>
					</Form>
				</div>
			</Spin>
		</PageContainer>
	);
};

export default ViewTheftScreen;
