import { Button, Card, Form, Input, Space, Select, Modal, notification, Result, Divider, Switch } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import { isDevelopment } from '../config';
import StoreContext from '../state/Store';
import styles from './styles/PushNotificationsScreen.module.scss';
import PageContainer from '../components/PageContainer';
import UnitContext from '../state/Unit';
import Unit from '@models/Unit';
import r from '../http';
import { Typography } from 'antd';
import Slider, { Marks } from '@components/form/Slider';

const { Title } = Typography;

const PushNotificationsScreen = () => {
	const { stores } = useContext(StoreContext);
	const { units: allUnits } = useContext(UnitContext);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showResultView, setShowResultView] = useState(false);
	const [pushNotificationSentCount, setPushNotificationSentCount] = useState<null | number>(null);
	const [pushNotificationSentStores, setPushNotificationSentStores] = useState('');
	const [form] = Form.useForm();

	const units = stores.map((store) => allUnits.find((unit) => unit.unitName === store.name)).filter(Boolean) as Unit[];

	const sendPushNotifications = async (formValues: any, selectedStoreNames: string) => {
		if (isDevelopment) {
			alert("Can't send push notifications is DEV");
			return;
		}
		setIsSubmitting(true);

		const response = await r.post<{ pushNotificationCount: number }>('/admin/send-push-notifications-firebase', {
			stores: units
				.filter((unit) => formValues.units.find((selectedStore: any) => selectedStore.value === unit.unitId))
				.map((unit) => unit.unitId),
			minAge: formValues.ageSpanEnabled ? formValues.ageSpan[0] : null,
			maxAge: formValues.ageSpanEnabled ? formValues.ageSpan[1] : null,
			title: formValues.title,
			body: formValues.body,
			subtitle: undefined,
		});

		setIsSubmitting(false);

		if (response.status !== 200) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
			return;
		}

		form.resetFields();

		setPushNotificationSentCount(response.data.pushNotificationCount);
		setPushNotificationSentStores(selectedStoreNames);
		setShowResultView(true);
	};

	const confirm = (formValues: any) => {
		const selectedStoreNames = formValues.units.map((unit: any) => unit.label).join(', ');
		const confirmationText = `Är du säker på att du vill skicka pushnotiser till alla personer som tillhör ${
			formValues.units.length > 1 ? 'butikerna' : 'butiken'
		}: ${selectedStoreNames}?`;

		Modal.confirm({
			title: 'Skicka pushnotiser',
			content: confirmationText,
			okText: 'Ja, skicka pushnotiser',
			cancelText: 'Avbryt',
			onOk: () => sendPushNotifications(formValues, selectedStoreNames),
		});
	};

	const selectedUnits = Form.useWatch('units', form) ?? [];
	const ageSpanEnabled = Form.useWatch('ageSpanEnabled', form);
	const ageSpan = Form.useWatch('ageSpan', form);
	const title = Form.useWatch('title', form);
	const body = Form.useWatch('body', form);

	useEffect(() => {
		const isTouched = form.isFieldsTouched(['title', 'body']);

		if (!isTouched) return;
		form.validateFields(['title']);
		form.validateFields(['body']);
	}, [title, body]);

	const required = !title && !body;

	const filteredUnits = units.filter(
		(unit) => !selectedUnits.find((selectedStore: any) => selectedStore.value === unit.unitId)
	);

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
	};

	return (
		<PageContainer title="Skicka pushnotiser">
			{showResultView ? (
				<Card>
					<Result
						status="success"
						title="Pushnotiser skickade!"
						subTitle={`Pushnotiser skickade till: ${pushNotificationSentStores}. Totalt ${pushNotificationSentCount} enheter`}
						extra={<Button onClick={() => setShowResultView(false)}>Tillbaka</Button>}
					/>
				</Card>
			) : (
				<Form {...layout} labelAlign="left" noValidate requiredMark={false} form={form} size="large" onFinish={confirm}>
					<Space direction="vertical" style={{ display: 'flex' }}>
						<Title level={3}>Mottagare</Title>
						<Card>
							<Form.Item
								label="Butik"
								name="units"
								rules={[{ required: true, message: 'Vänligen välj minst en butik' }]}
							>
								<Select
									filterOption={(input, option) =>
										(option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
									}
									mode="multiple"
									placeholder="Butiker"
									allowClear
									labelInValue
									style={{ width: '100%' }}
								>
									{filteredUnits.map((unit) => (
										<Select.Option key={unit?.unitId} title={unit?.unitName} value={unit?.unitId}>
											{unit?.unitName}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Divider />
							<Form.Item label="Rikta mot ålder" name="ageSpanEnabled" valuePropName="checked">
								<Switch />
							</Form.Item>
							{ageSpanEnabled && (
								<>
									<Divider />
									<Form.Item label="Åldersspann" name="ageSpan" initialValue={[18, 30]}>
										<Slider min={0} max={120} range marks={getMarks(ageSpan)} step={1} />
									</Form.Item>
								</>
							)}
						</Card>
						<Title level={3}>Notisinnehåll</Title>
						<Card>
							<Form.Item
								label="Titel"
								name="title"
								rules={[{ required, message: 'Vänligen ange titel eller innehåll' }]}
							>
								<Input placeholder="Titel" />
							</Form.Item>
							<Divider />
							<Form.Item
								label="Innehåll"
								name="body"
								rules={[{ required, message: 'Vänligen ange innehåll eller titel' }]}
							>
								<Input placeholder="Innehåll" />
							</Form.Item>
						</Card>
					</Space>
					{stores.length > 0 ? (
						<div className={styles.actions}>
							<Form.Item>
								<Space size="middle">
									<Button type="primary" htmlType="submit" loading={isSubmitting}>
										Skicka pusnotiser <SendOutlined />
									</Button>
								</Space>
							</Form.Item>
						</div>
					) : (
						<></>
					)}
				</Form>
			)}
		</PageContainer>
	);
};

export default PushNotificationsScreen;

function getMarks(ageSpan: [number, number] | undefined) {
	const marks: Marks = {
		0: '0 År',
		120: '120 År',
	};

	if (ageSpan) {
		const age1 = ageSpan[0];
		const age2 = ageSpan[1];

		marks[age1] = {
			style: {
				fontWeight: 'bold',
			},
			label: `${age1} år`,
		};
		marks[age2] = {
			style: {
				fontWeight: 'bold',
			},
			label: `${age2} år`,
		};
	}

	return marks;
}
