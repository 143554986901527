import notificationSound from '../assets/audio/notification.wav';
import { Howl } from 'howler';

export const playNotificationSound = () => {
	const sound = new Howl({
		src: [notificationSound],
		autoplay: false,
		loop: false,
		volume: 0.5,
	});
	sound.play();
};
