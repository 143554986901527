import styles from './styles/CreateTagScreen.module.scss';
import { useContext, useEffect, useState } from 'react';
import StoreContext from '../state/Store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Card, Form, Input, Space, notification } from 'antd';
import PageContainer from '@components/PageContainer';
import { PlusOutlined } from '@ant-design/icons';
import r from '../http';

const CreateTagScreen = () => {
	const { stores, defaultStore } = useContext(StoreContext);

	const [searchParams, setSearchParams] = useSearchParams();
	const getStoreIdParam = (searchParams: URLSearchParams) => {
		const storeIdParam = searchParams.get('storeId');
		return storeIdParam && stores.find((s) => s.id === parseInt(storeIdParam)) !== undefined
			? parseInt(storeIdParam)
			: null;
	};
	const storeIdParam = getStoreIdParam(searchParams);
	const [selectedStore] = useState(storeIdParam || defaultStore);
	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [form] = Form.useForm();

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
	};

	const submit = async (form: { cardName: string }) => {
		const store = stores.find((x) => x.id === selectedStore);

		if (!store) return;

		const body = {
			lockId: store.lockId.toString(),
			cardNumber: '00001',
			cardName: form.cardName,
			startDate: 0,
			endDate: 0,
			cardType: 1,
			addType: 2,
		};

		try {
			setIsSubmitting(true);

			await r.post(`/admin/stores/${selectedStore}/ic-cards`, body);

			notification.success({
				message: 'Tagg skapad!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	useEffect(() => {
		if (selectedStore === -1) {
			setSearchParams('', { replace: true });
			return;
		}

		setSearchParams(`storeId=${selectedStore}`, { replace: true });
	}, [selectedStore, setSearchParams]);

	return (
		<PageContainer back title="Skapa tagg">
			<Form initialValues={{ cardName: '' }} {...layout} labelAlign="left" form={form} size="large" onFinish={submit}>
				<Card>
					<Form.Item label="Namn" name="cardName" rules={[{ required: true, message: 'Vänligen ange ett namn' }]}>
						<Input placeholder="Namn" />
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button icon={<PlusOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
								Skapa tagg
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default CreateTagScreen;
