import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import logo from '../assets/images/logo-black.png';

const LoaderWithLogo = () => {
	return (
		<div
			style={{
				display: 'flex',
				flexGrow: 1,
				height: '100vh',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
			}}
		>
			<img style={{ height: 36, marginBottom: '12px' }} src={logo} alt="logo" />
			<Spin indicator={<LoadingOutlined spin />} size="large" />
		</div>
	);
};

export default LoaderWithLogo;
