import styles from './styles/EditVendingMachineScreen.module.scss';
import { Button, Card, Divider, Form, Input, InputNumber, Select, Space, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useContext, useMemo, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import r from '../../http';
import PageContainer from '../../components/PageContainer';
import VendingMachineContext from '../../state/VendingMachine';
import StoreContext from '../../state/Store';
import { VendingMachine } from '@models/VendingMachine';
import { typeOptions } from '../utils';
import LoaderWithLogo from '@components/LoaderWithLogo';

const EditVendingMachineScreen = () => {
	const { stores } = useContext(StoreContext);
	const { vendingMachines, updateVendingMachine } = useContext(VendingMachineContext);

	const [selectedStore, setSelectedStore] = useState<number | undefined>(undefined);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const navigate = useNavigate();
	const { id } = useParams();
	const [form] = Form.useForm();

	const vendingMachine = useMemo(
		() => vendingMachines.find((vendingMachine) => vendingMachine.id === parseInt(id!)),
		[vendingMachines, id]
	);

	useEffect(() => {
		if (vendingMachine !== undefined && selectedStore === undefined) setSelectedStore(vendingMachine.storeId);
	}, [vendingMachine, selectedStore]);

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
	};

	if (!vendingMachine || selectedStore === undefined) {
		return <LoaderWithLogo />;
	}

	const submit = async (form: any) => {
		const body = new FormData();
		body.append('id', id!);
		body.append('name', form.name);
		body.append('type', form.type);
		body.append('storeId', form.store || '0');
		body.append('deviceId', form.deviceId);
		body.append('latitude', form.latitude);
		body.append('longitude', form.longitude);
		body.append('maxProductsPerUserPerHour', form.maxProductsPerUserPerHour);
		try {
			setIsSubmitting(true);
			const response = await r.put<VendingMachine>(`/admin/vending-machines/${vendingMachine.id}`, body);
			updateVendingMachine(response.data);
			notification.success({
				message: 'Varuautomat uppdaterad!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	return (
		<PageContainer back title="Uppdatera varuautomat">
			<Form
				{...layout}
				labelAlign="left"
				form={form}
				size="large"
				onFinish={submit}
				initialValues={{
					store: vendingMachine.storeId || undefined,
					name: vendingMachine.name,
					type: vendingMachine.type,
					deviceId: vendingMachine.deviceId,
					latitude: vendingMachine.latitude,
					longitude: vendingMachine.longitude,
					maxProductsPerUserPerHour: vendingMachine.maxProductsPerUserPerHour,
				}}
			>
				<Card>
					<Form.Item label="Namn" name="name" rules={[{ required: true, message: 'Vänligen ange namn' }]}>
						<Input placeholder="Namn" />
					</Form.Item>
					<Divider />
					<Form.Item label="Enhets ID" name="deviceId" rules={[{ required: true, message: 'Vänligen ange enhets ID' }]}>
						<Input placeholder="Enhets ID" />
					</Form.Item>
					<Divider />
					<Form.Item
						extra="Detta värdet används bara för äldre appversioner när varuautomater var kopplade direkt till butiken"
						label="Butik"
						name="store"
					>
						<Select
							placeholder="Butik"
							allowClear
							style={{ width: 200 }}
							options={stores.map((s) => {
								return { value: s.id, label: s.name };
							})}
						/>
					</Form.Item>
					<Divider />
					<Form.Item label="Typ" name="type" rules={[{ required: true, message: 'Vänligen välj type' }]}>
						<Select placeholder="Typ" allowClear style={{ width: 200 }}>
							{typeOptions.map((typeOption) => (
								<Select.Option key={typeOption.value} title={typeOption.label} value={typeOption.value}>
									{typeOption.label}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Divider />
					<Form.Item label="Latitude" name="latitude" rules={[{ required: true, message: 'Vänligen ange latitude' }]}>
						<Input placeholder="Latitude" />
					</Form.Item>
					<Divider />
					<Form.Item
						label="Longitude"
						name="longitude"
						rules={[{ required: true, message: 'Vänligen ange longitude' }]}
					>
						<Input placeholder="Longitude" />
					</Form.Item>
					<Divider />
					<Form.Item
						label="Max köp per timme"
						name="maxProductsPerUserPerHour"
						rules={[{ required: true, message: 'Vänligen ange max köp per timme' }]}
					>
						<InputNumber style={{ width: 200 }} placeholder="Max köp per timme" />
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button icon={<EditOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
								Uppdatera varuautomat
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default EditVendingMachineScreen;
