import { Divider } from 'antd';
import { PageHeader, PageHeaderProps } from '@ant-design/pro-layout';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/PageContainer.module.scss';

interface Props {
	title: string;
	subTitle?: string;
	back?: boolean;
	extra?: PageHeaderProps['extra'];
	children?: React.ReactNode;
}

const PageContainer = ({ title, subTitle, back, children, extra }: Props) => {
	const navigate = useNavigate();

	const onBack = back ? () => navigate(-1) : undefined;

	return (
		<div className={styles.container}>
			<PageHeader className={styles.header} onBack={onBack} title={title} subTitle={subTitle} extra={extra} />
			<div className={styles.content}>
				<Divider className={styles.divider} />
				{children}
			</div>
		</div>
	);
};

export default PageContainer;
