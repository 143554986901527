import { Button, Space, Spin, notification } from 'antd';
import PageContainer from '../../components/PageContainer';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import styles from './styles/AssortmentInformationScreen.module.scss';
import TextEditor from '../../components/form/TextEditor';
import Editor from '@toast-ui/editor';
import useFetch from '../../hooks/fetch';
import Setting from '../../models/Setting';
import r from '../../http';
import useRoles from '@hooks/roles';

const EditAssortmentInformationScreen = () => {
	const { isAdmin } = useRoles();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [setting, setSetting] = useState<Setting | undefined>(undefined);
	const [initialValue, setInitialValue] = useState<string>('');
	const editor = useRef<Editor | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (!isAdmin) {
			navigate('/information/assortment');
			return;
		}
	}, []);

	const { isFetching } = useFetch<Setting>('/admin/settings/assortmentInformation', setSetting, () => {
		notification.error({
			message: 'Vi kunde inte hämta informationen. Vänligen försök igen.',
			placement: 'bottomRight',
			duration: 10,
		});
	});

	useEffect(() => {
		if (setting === undefined) return;
		setInitialValue(setting.value);
	}, [setting]);

	const save = async () => {
		if (!isAdmin || setting === undefined) return;
		setIsSubmitting(true);
		const text = editor.current?.getMarkdown();

		try {
			await r.put<Setting>(`/admin/settings/assortmentInformation`, {
				name: setting.name,
				value: text,
			});

			navigate('/information/assortment');
			notification.success({
				message: 'Ändringar sparade!',
				placement: 'bottomRight',
				duration: 5,
			});
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}

		setIsSubmitting(false);
	};

	return (
		<PageContainer title="Redigera information för handlare" back>
			{initialValue && <TextEditor ref={editor} initialValue={initialValue} />}
			<Spin spinning={isFetching} tip="Hämtar information...">
				<div className={styles.actions}>
					<Space size="middle">
						<Button size="large" onClick={() => navigate('/information/assortment')} type="text">
							Avbryt
						</Button>
						<Button
							onClick={save}
							htmlType="submit"
							size="large"
							icon={<EditOutlined />}
							type="primary"
							loading={isSubmitting}
						>
							Spara information
						</Button>
					</Space>
				</div>
			</Spin>
		</PageContainer>
	);
};

export default EditAssortmentInformationScreen;
