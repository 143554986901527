import { useContext, useMemo } from 'react';
import AuthContext from '../state/Auth';
import Role from '../models/Role';

const useRoles = () => {
	const { user } = useContext(AuthContext);

	const isAdmin = useMemo(() => user?.roles.includes(Role.Admin) ?? false, [user]);
	const isMaintainer = useMemo(() => user?.roles.includes(Role.Maintainer) ?? false, [user]);
	const isFranchise = useMemo(() => user?.roles.includes(Role.FranchiceAdmin) ?? false, [user]);

	const isAdminOrMaintainer = useMemo(() => isAdmin || isMaintainer, [isAdmin, isMaintainer]);

	return { isAdmin, isMaintainer, isFranchise, isAdminOrMaintainer };
};

export default useRoles;
