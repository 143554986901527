import { Navigate, Route, Routes } from 'react-router-dom';
import AccountScreen from '../account/AccountScreen';
import LoginScreen from '../auth/login/LoginScreen';
import ResetPasswordScreen from '../auth/reset-password/ResetPasswordScreen';
import ChangePasswordScreen from '../auth/change-password/ChangePasswordScreen';
import NewsArticleScreen from '../news/NewsArticleScreen';
import EditNewsArticleScreen from '../news/EditNewsArticleScreen';
import CreateNewsArticleScreen from '../news/CreateNewsArticleScreen';
import OrderScreen from '../order/OrderScreen';
import SummaryScreen from '../order/OrderSummaryScreen';
import MyOrdersScreen from '../order/ShowOrdersScreen';
import ProductsScreen from '../products/ProductsScreen';
import EditProductScreen from '../products/EditProductScreen';
import CreateProductScreen from '../products/CreateProductScreen';
import { StoreContextProvider } from '../state/Store';
import AuthContext from '../state/Auth';
import { OrderContextProvider } from '../state/Order';
import ViewStoreScreen from '../stores/view/ViewStoreScreen';
import Layout from './components/Layout';
import EditStoreScreen from '../stores/edit/EditStoreScreen';
import React, { useContext } from 'react';
import { Result } from 'antd';
import ScrollToTop from './components/ScrollToTop';
import EventsScreen from '../events/EventsScreen';
import BannedAccountsScreen from '../banned-accounts/list/BannedAccountsScreen';
import CreateBannedAccountScreen from '../banned-accounts/create/CreateBannedAccountScreen';
import PushNotificationsScreen from '../push-notifications/PushNotificationsScreen';
import OffersScreen from '../offers/OffersScreen';
import CreateOfferScreen from '../offers/CreateOfferScreen';
import ViewOfferScreen from '../offers/ViewOfferScreen';
import EditOfferScreen from '../offers/EditOfferScreen';
import { OfferContextProvider } from '../state/Offer';
import { AccountApplicationContextProvider } from '../state/AccountApplication';
import CommonQuestionsScreen from '../common-questions/CommonQuestionsScreen';
import TermsAndConditions from '../terms-and-conditions/TermsAndConditions';
import { ProductContextProvider } from '../state/Product';
import { NewsArticleContextProvider } from '../state/NewsArticle';
import StoresScreen from '../stores/StoresScreen';
import ViewProductScreen from '../products/ViewProductScreen';
import ViewNewsArticleScreen from '../news/ViewNewsArticle';
import OfferPrintScreen from '../offer-print/OfferPrintScreen';
import { TheftContextProvider } from '../state/Theft';
import TheftScreen from '../theft/TheftScreen';
import ViewTheftScreen from '../theft/ViewTheftScreen';
import ReportTheftScreen from '../theft/ReportTheftScreen';
import ApplicationsScreen from '../applications/ApplicationsScreen';
import { VendingMachineApplicationContextProvider } from '../state/VendingMachineApplication';
import { PurchaseContextProvider } from '../state/Purchase';
import AssortmentInformationScreen from '../information/assortment/AssortmentInformationScreen';
import VendingMachineEvents from '../vending-machine-events/VendingMachineEvents';
import TobaccoProductsScreen from '../tobacco-products/list/TobaccoProductsScreen';
import TobaccoProductDetailsScreen from '../tobacco-products/details/TobaccoProductDetailsScreen';
import CreateTobaccoProductScreen from '../tobacco-products/create/CreateTobaccoProductScreen';
import EditTobaccoProductScreen from '../tobacco-products/edit/EditTobaccoProductScreen';
import { TobaccoProductContextProvider } from '../state/TobaccoProduct';
import { ProductCategoryContextProvider } from '../state/ProductCategory';
import EditAssortmentInformationScreen from '../information/assortment/EditAssortmentInformationScreen';
import AnnouncementScreen from '../announcements/AnnouncementScreen';
import { AnnouncementContextProvider } from '../state/Announcement';
import CreateAnnouncementScreen from '../announcements/CreateAnnouncementScreen';
import EditAnnouncementScreen from '../announcements/EditAnnouncementScreen';
import ViewAnnouncementScreen from '../announcements/ViewAnnouncementScreen';
import { UnitContextProvider } from '../state/Unit';
import VendingMachinesScreen from '../vending-machines/list/VendingMachinesScreen';
import { VendingMachineContextProvider } from '../state/VendingMachine';
import CreateVendingMachineScreen from '../vending-machines/create/CreateVendingMachineScreen';
import ViewVendingMachineScreen from '../vending-machines/details/ViewVendingMachineScreen';
import EditVendingMachineScreen from '../vending-machines/edit/EditVendingMachineScreen';
import BannedAccountDetailScreen from '../banned-accounts/detail/BannedAccountDetailScreen';
import EditBannedAccountScreen from '../banned-accounts/edit/EditBannedAccountScreen';
import { BannedUserContextProvider } from '../state/BannedUsers';
import UsersScreen from '../users/list/UsersScreen';
import { UserContextProvider } from '../state/User';
import CreateUserScreen from '../users/create/CreateUserScreen';
import UserDetailsScreen from '../users/details/UserDetailsScreen';
import EditUserScreen from '../users/edit/EditUserScreen';
import CenterDiv from '@components/CenterDiv';
import TagScreen from '../tags/TagScreen';
import CreateTagScreen from '../tags/CreateTagScreen';
import CreateStoreScreen from '../stores/create/CreateStoreScreen';
import { DiscountContextProvider } from '../state/Discount';
import DiscountsScreen from '../discounts/DiscountScreen';
import CreateDiscountScreen from '../discounts/CreateDiscountScreen';
import EditDiscountScreen from '../discounts/EditDiscountScreen';
import ViewDiscountScreen from '../discounts/ViewDiscountScreen';
import { ScienerLockContextProvider } from '../state/ScienerLocks';
import { EventContextProvider } from '../state/Event';
import useRoles from '@hooks/roles';
import LoaderWithLogo from '@components/LoaderWithLogo';

const PageNotFound = () => (
	<CenterDiv>
		<Result status="404" title="Sidan kunde inte hittas" />
	</CenterDiv>
);

const AuthNavigation = () => {
	const auth = useContext(AuthContext);

	if (auth.authenticated) {
		return <Navigate to="/" replace />;
	}

	return (
		<Routes>
			<Route path="/" element={<LoginScreen />} />
			<Route path="/reset-password" element={<ResetPasswordScreen />} />
			<Route path="/change-password" element={<ChangePasswordScreen />} />
		</Routes>
	);
};

const StoreNavigation = () => {
	const { isMaintainer } = useRoles();

	return (
		<Layout>
			<Routes>
				<Route path="/" element={<Navigate to={isMaintainer ? 'applications' : '/stores'} replace={true} />} />
				<Route path="/account" element={<AccountScreen />} />
				<Route path="/stores" element={<StoresScreen />} />
				<Route path="/stores/create" element={<CreateStoreScreen />} />
				<Route path="/stores/:id" element={<ViewStoreScreen />} />
				<Route path="/stores/:id/edit" element={<EditStoreScreen />} />
				<Route path="/information/assortment" element={<AssortmentInformationScreen />} />
				<Route path="/information/assortment/edit" element={<EditAssortmentInformationScreen />} />
				<Route path="/news" element={<NewsArticleScreen />} />
				<Route path="/news/:id" element={<ViewNewsArticleScreen />} />
				<Route path="/news/:id/edit" element={<EditNewsArticleScreen />} />
				<Route path="/news/create" element={<CreateNewsArticleScreen />} />
				<Route path="/order" element={<OrderScreen />} />
				<Route path="/order/summary" element={<SummaryScreen />} />
				<Route path="/order/my-orders" element={<MyOrdersScreen />} />
				<Route path="/products" element={<ProductsScreen />} />
				<Route path="/products/:id" element={<ViewProductScreen />} />
				<Route path="/products/:id/edit" element={<EditProductScreen />} />
				<Route path="/products/create" element={<CreateProductScreen />} />
				<Route path="/events" element={<EventsScreen />} />
				<Route path="/vending-machine-events" element={<VendingMachineEvents />} />
				<Route path="/applications" element={<ApplicationsScreen />} />
				<Route path="/users" element={<UsersScreen />} />
				<Route path="/users/create" element={<CreateUserScreen />} />
				<Route path="/users/:id" element={<UserDetailsScreen />} />
				<Route path="/users/:id/edit" element={<EditUserScreen />} />
				<Route path="/banned-accounts" element={<BannedAccountsScreen />} />
				<Route path="/banned-accounts/:id" element={<BannedAccountDetailScreen />} />
				<Route path="/banned-accounts/:id/edit" element={<EditBannedAccountScreen />} />
				<Route path="/banned-accounts/new" element={<CreateBannedAccountScreen />} />
				<Route path="/banned-accounts-franchise" element={<CreateBannedAccountScreen />} />
				<Route path="/thefts" element={<TheftScreen />} />
				<Route path="/thefts/:id" element={<ViewTheftScreen />} />
				<Route path="/thefts/report" element={<ReportTheftScreen />} />
				<Route path="/push-notifications" element={<PushNotificationsScreen />} />
				<Route path="/offers" element={<OffersScreen />} />
				<Route path="/offers/create" element={<CreateOfferScreen />} />
				<Route path="/offers/:id" element={<ViewOfferScreen />} />
				<Route path="/offers/:id/edit" element={<EditOfferScreen />} />
				<Route path="/discounts" element={<DiscountsScreen />} />
				<Route path="/discounts/create" element={<CreateDiscountScreen />} />
				<Route path="/discounts/:id" element={<ViewDiscountScreen />} />
				<Route path="/discounts/:id/edit" element={<EditDiscountScreen />} />
				<Route path="/offer-print" element={<OfferPrintScreen />} />
				<Route path="/tags" element={<TagScreen />} />
				<Route path="/tags/create" element={<CreateTagScreen />} />
				<Route path="/vending-machines" element={<VendingMachinesScreen />} />
				<Route path="/vending-machines/:id" element={<ViewVendingMachineScreen />} />
				<Route path="/vending-machines/:id/edit" element={<EditVendingMachineScreen />} />
				<Route path="/vending-machines/create" element={<CreateVendingMachineScreen />} />
				<Route path="/tobacco-products" element={<TobaccoProductsScreen />} />
				<Route path="/tobacco-products/create" element={<CreateTobaccoProductScreen />} />
				<Route path="/tobacco-products/:id" element={<TobaccoProductDetailsScreen />} />
				<Route path="/tobacco-products/:id/edit" element={<EditTobaccoProductScreen />} />
				<Route path="/announcements" element={<AnnouncementScreen />} />
				<Route path="/announcements/create" element={<CreateAnnouncementScreen />} />
				<Route path="/announcements/:id" element={<ViewAnnouncementScreen />} />
				<Route path="/announcements/:id/edit" element={<EditAnnouncementScreen />} />
				<Route path="/common-questions" element={<CommonQuestionsScreen />} />
				<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
				{/* <Route path="/settings" element={<SettingsScreen />} /> */}
				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</Layout>
	);
};

const withContext =
	(WrappedComponent: React.FC) =>
	// eslint-disable-next-line react/display-name
	({ ...props }) =>
		(
			<UserContextProvider>
				<BannedUserContextProvider>
					<StoreContextProvider>
						<UnitContextProvider>
							<ProductContextProvider>
								<OrderContextProvider>
									<AnnouncementContextProvider>
										<DiscountContextProvider>
											<OfferContextProvider>
												<NewsArticleContextProvider>
													<AccountApplicationContextProvider>
														<ProductCategoryContextProvider>
															<VendingMachineContextProvider>
																<TobaccoProductContextProvider>
																	<VendingMachineApplicationContextProvider>
																		<PurchaseContextProvider>
																			<TheftContextProvider>
																				<ScienerLockContextProvider>
																					<EventContextProvider>
																						<WrappedComponent {...props} />
																					</EventContextProvider>
																				</ScienerLockContextProvider>
																			</TheftContextProvider>
																		</PurchaseContextProvider>
																	</VendingMachineApplicationContextProvider>
																</TobaccoProductContextProvider>
															</VendingMachineContextProvider>
														</ProductCategoryContextProvider>
													</AccountApplicationContextProvider>
												</NewsArticleContextProvider>
											</OfferContextProvider>
										</DiscountContextProvider>
									</AnnouncementContextProvider>
								</OrderContextProvider>
							</ProductContextProvider>
						</UnitContextProvider>
					</StoreContextProvider>
				</BannedUserContextProvider>
			</UserContextProvider>
		);

const HomeNavigation = withContext(() => {
	const { authenticated } = useContext(AuthContext);
	const { user } = useContext(AuthContext);

	if (!authenticated) {
		return <Navigate to="/login" replace />;
	}

	if (!user) {
		return (
			<CenterDiv>
				<LoaderWithLogo />
			</CenterDiv>
		);
	}

	return (
		<ScrollToTop>
			<Routes>
				<Route path="*" element={<StoreNavigation />} />
			</Routes>
		</ScrollToTop>
	);
});

const Navigation = () => {
	const { authReady } = useContext(AuthContext);

	if (!authReady) {
		return (
			<CenterDiv>
				<LoaderWithLogo />
			</CenterDiv>
		);
	}

	return (
		<Routes>
			<Route path="*" element={<HomeNavigation />} />
			<Route path="/login/*" element={<AuthNavigation />} />
		</Routes>
	);
};

export default Navigation;
