import { Card, Form, Image, InputNumber, Modal, Select, Space, Spin, Typography, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import { VendingMachineProduct, VendingMachineProductResponse } from '@models/VendingMachineProducts';
import TobaccoProductContext from '../../../state/TobaccoProduct';
import r from '../../../http';

const { Text } = Typography;

interface Props {
	open: boolean;
	vendingMachineId: number;
	existingProducts: VendingMachineProduct[];
	vendingMachineProduct: VendingMachineProduct | undefined;
	editProduct: (products: VendingMachineProductResponse) => void;
	onClose: () => void;
}

const layout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 14 },
};

const EditVendingMachineProductModal = ({
	open,
	vendingMachineId,
	existingProducts,
	vendingMachineProduct,
	onClose,
	editProduct,
}: Props) => {
	const { tobaccoProducts } = useContext(TobaccoProductContext);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [form] = Form.useForm<{
		position: number | undefined;
		price: number | undefined;
		product: number | undefined;
	}>();

	useEffect(() => {
		// There is a bug only in production build where the previously opened product will show instead of the one just opened.
		form.setFieldsValue({
			position: vendingMachineProduct?.position,
			price: vendingMachineProduct?.price,
			product: vendingMachineProduct?.tobaccoProduct.id,
		});
	}, [vendingMachineProduct, form, open]);

	const submit = async (formValues: any) => {
		if (vendingMachineProduct === undefined) return;
		setIsSubmitting(true);

		try {
			const body = new FormData();
			body.append('id', vendingMachineProduct.id.toString());
			body.append('vendingMachineId', vendingMachineId.toString());
			//changeable
			body.append('position', formValues.position);
			body.append('price', formValues.price);
			body.append('tobaccoProductId', formValues.product);

			const response = await r.put<VendingMachineProductResponse>(
				`/admin/vending-machine-products/${vendingMachineProduct.id}`,
				body
			);

			editProduct(response.data);

			notification.success({
				message: 'Produkt tillagd till varuautomat!',
				placement: 'bottomRight',
				duration: 5,
			});
			form.resetFields();
			onClose();
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}

		setIsSubmitting(false);
	};

	return (
		<Modal
			title="Uppdatera produkt i varuautomat"
			centered
			open={open}
			onOk={() => form.submit()}
			onCancel={onClose}
			width={600}
			okText={`Uppdatera produkt`}
			cancelText="Avbryt"
			cancelButtonProps={{
				size: 'large',
				type: 'text',
			}}
			okButtonProps={{
				size: 'large',
				loading: isSubmitting,
				icon: <EditOutlined />,
				htmlType: 'submit',
			}}
			destroyOnClose
		>
			{open && vendingMachineProduct ? (
				<Form
					{...layout}
					labelAlign="left"
					form={form}
					size="large"
					onFinish={submit}
					preserve={false}
					initialValues={{
						position: vendingMachineProduct?.position,
						price: vendingMachineProduct?.price,
						product: vendingMachineProduct.tobaccoProduct.id,
					}}
				>
					<Card>
						<Form.Item
							name="position"
							label="Position"
							rules={[
								{ required: true, message: 'Vänligen ange en position.' },
								{
									validator(_, value) {
										if (
											vendingMachineProduct.position === value ||
											!existingProducts.map((p) => p.position).includes(value)
										) {
											return Promise.resolve();
										}
										return Promise.reject(new Error());
									},
									message: 'Positionen är upptagen.',
								},
							]}
							extra="Produktens position i varuautomaten."
						>
							<InputNumber style={{ width: 150 }} type="number" />
						</Form.Item>
						<Form.Item name="price" label="Pris" rules={[{ required: true, message: 'Vänligen ange ett pris.' }]}>
							<InputNumber addonAfter="SEK" min={0} style={{ width: 150 }} type="number" />
						</Form.Item>
						<Form.Item
							name="product"
							label="Produkt"
							rules={[{ required: true, message: 'Vänligen välj en produkt.' }]}
							initialValue={vendingMachineProduct.tobaccoProduct.id} // Set the
						>
							<Select
								showSearch
								allowClear
								filterOption={(input, option) =>
									option?.props['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								<Select.Option
									value={vendingMachineProduct.tobaccoProduct.id}
									key={vendingMachineProduct.tobaccoProduct.id}
									data-name={vendingMachineProduct.tobaccoProduct.name}
								>
									<Space>
										<Image src={vendingMachineProduct.tobaccoProduct.imageUrl} width={30} />
										<Text strong>{vendingMachineProduct.tobaccoProduct.name}</Text>
									</Space>
								</Select.Option>
								{tobaccoProducts
									.filter((p) => !existingProducts.map((vmp) => vmp.tobaccoProduct).includes(p))
									.sort((a, b) => a.name.localeCompare(b.name))
									.map((p) => (
										<Select.Option value={p.id} key={p.id} data-name={p.name}>
											<Space>
												<Image src={p.imageUrl} width={30} />
												{p.name}
											</Space>
										</Select.Option>
									))}
							</Select>
						</Form.Item>
					</Card>
				</Form>
			) : (
				<Spin>Hämtar produkt...</Spin>
			)}
		</Modal>
	);
};

export default EditVendingMachineProductModal;
