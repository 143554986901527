import { useContext, useEffect, useState } from 'react';
import StoreContext from '../../state/Store';
import { Image, Button, Spin, Divider, Card, Row, Col, Typography, Space } from 'antd';
import { DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import StoreProducts from './components/StoreProducts';
import QRCode from 'qrcode';
import { captureException } from '@sentry/react';
import useRoles from '@hooks/roles';

const { Text } = Typography;

const ViewStoreScreen = () => {
	const { stores } = useContext(StoreContext);
	const { isAdmin } = useRoles();
	const navigate = useNavigate();
	const id = useParams().id || '0';
	const [qrCodeImage, setQrCodeImage] = useState<null | string>(null);

	const store = stores.find((store) => store.id === parseInt(id));

	const labelLayout = {
		md: 6,
		sm: 24,
		xs: 24,
	};

	const valueLayout = {
		md: 12,
		sm: 24,
		xs: 24,
	};

	const onDownloadQrCode = () => {
		if (!qrCodeImage) return;

		fetch(qrCodeImage)
			.then((response) => response.blob())
			.then((blob) => {
				const url = URL.createObjectURL(new Blob([blob]));
				const link = document.createElement('a');
				link.href = url;
				link.download = 'image.png';
				document.body.appendChild(link);
				link.click();
				URL.revokeObjectURL(url);
				link.remove();
			});
	};

	useEffect(() => {
		if (!store) return;

		const openStoreUrl = `https://open.24food.se/?store=${store.storeGuid}`;

		QRCode.toDataURL(
			openStoreUrl,
			{
				errorCorrectionLevel: 'H',
				type: 'image/jpeg',
				margin: 1,
				width: 1000,
			},
			(error, imageSrc) => {
				if (error) {
					captureException(error);
					return;
				}

				setQrCodeImage(imageSrc);
			}
		);
	}, [store]);

	return (
		<PageContainer
			title="Butik detaljer"
			back
			subTitle={store?.name}
			extra={[<Button key="edit" onClick={() => navigate('edit')} type="primary" icon={<EditOutlined />} />]}
		>
			<Spin spinning={!store} tip="Hämtar butik...">
				<Card>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Namn
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{store?.name}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Bild
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Image src={store?.imageUrl} />
						</Col>
						<Divider />
					</Row>
					{isAdmin && (
						<>
							<Row>
								<Col {...labelLayout}>
									<Text strong type="secondary">
										Lås ID
									</Text>
								</Col>
								<Col {...valueLayout}>
									<Text>{store?.lockId}</Text>
								</Col>
								<Divider />
							</Row>
							<Row>
								<Col {...labelLayout}>
									<Text strong type="secondary">
										Position latitude
									</Text>
								</Col>
								<Col {...valueLayout}>
									<Text>{store?.latitude}</Text>
								</Col>
								<Divider />
							</Row>
							<Row>
								<Col {...labelLayout}>
									<Text strong type="secondary">
										Position Longitude
									</Text>
								</Col>
								<Col {...valueLayout}>
									<Text>{store?.longitude}</Text>
								</Col>
								<Divider />
							</Row>
						</>
					)}
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Stad
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{store?.city}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Adress
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{store?.address}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Postnummer
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{store?.postalCode}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Information
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{store?.information}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								E-post
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{store?.email}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								Telefonnummer
							</Text>
						</Col>
						<Col {...valueLayout}>
							<Text>{store?.phoneNumber}</Text>
						</Col>
						<Divider />
					</Row>
					<Row>
						<Col {...labelLayout}>
							<Text strong type="secondary">
								QR-Kod för öppning av dörr
							</Text>
						</Col>
						<Col {...valueLayout}>
							{qrCodeImage && (
								<Space direction="vertical">
									<Image width={200} src={qrCodeImage} />
									<Button onClick={onDownloadQrCode} type="primary" icon={<DownloadOutlined />}>
										Ladda ner QR-Kod som bild
									</Button>
								</Space>
							)}
						</Col>
					</Row>
				</Card>
			</Spin>
			{store && isAdmin && <StoreProducts storeId={store.id} />}
		</PageContainer>
	);
};

export default ViewStoreScreen;
