import { Select } from 'antd';
import Store from '../models/Store';

interface Props {
	stores: Store[];
	selectedStore: number | null;
	onChange: (storeId: number) => void;
	className?: string;
	all?: boolean;
	width?: number | string;
}

const StoreSelector = ({ stores, selectedStore, onChange, all = false, className, width = 200 }: Props) => {
	return (
		<Select
			className={className}
			value={selectedStore}
			onChange={onChange}
			placeholder="Välj butik"
			size="large"
			style={{ width }}
		>
			{all && <Select.Option value={-1}>Alla butiker</Select.Option>}
			{stores.map((store) => (
				<Select.Option key={store.id} value={store.id}>
					{store.name}
				</Select.Option>
			))}
		</Select>
	);
};

export default StoreSelector;
