import { Button, Divider, Table, Input, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';
import { useContext, useEffect, useState } from 'react';
import styles from './styles/DiscountsScreen.module.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Discount from '../models/Discount';
import DiscountActions from './components/DiscountActions';
import DiscountContext from '../state/Discount';
import PageContainer from '../components/PageContainer';
import StoreContext from '../state/Store';
import StoreSelector from '../components/StoreSelector';
import useFetch from '../hooks/fetch';
import Separator from '../components/Separator';
import ListImage from '@components/table/ListImage';
import useRoles from '@hooks/roles';

const { Search } = Input;

const columns: ColumnsType<Discount> = [
	{
		title: 'QR-kod',
		dataIndex: 'imageUrl',
		key: 'imageUrl',
		render: (imageUrl) => <ListImage src={imageUrl} />,
	},
	{
		title: 'Beskrivning',
		dataIndex: 'text',
		key: 'text',
	},
	{
		width: 150,
		responsive: ['sm'],
		fixed: 'right',
		render: (_, discount: Discount) => <DiscountActions id={discount.id} />,
	},
	{
		responsive: ['xs'],
		width: 50,
		fixed: 'right',
		render: (_, discount: Discount) => <DiscountActions id={discount.id} />,
	},
];

const DiscountsScreen = () => {
	const { stores, defaultStore } = useContext(StoreContext);
	const { discounts, setDiscounts } = useContext(DiscountContext);
	const { isAdmin } = useRoles();
	const [searchParams, setSearchParams] = useSearchParams();
	const getStoreIdParam = (searchParams: URLSearchParams) => {
		const storeIdParam = searchParams.get('storeId');
		return storeIdParam && stores.find((s) => s.id === parseInt(storeIdParam)) !== undefined
			? parseInt(storeIdParam)
			: null;
	};
	const storeIdParam = getStoreIdParam(searchParams);

	const [selectedStore, setSelectedStore] = useState<number>(storeIdParam || (isAdmin ? -1 : defaultStore));
	const [searchTerm, setSearchterm] = useState('');

	const navigate = useNavigate();

	useEffect(() => {
		if (selectedStore === -1 || selectedStore === undefined) {
			setSearchParams('');
			return;
		}

		setSearchParams(`storeId=${selectedStore}`);
	}, [selectedStore, setSearchParams]);

	const { isFetching } = useFetch<Discount[]>(
		'/admin/discounts',
		setDiscounts,
		() => {
			notification.error({
				message: 'Vi kunde inte hämta rabattkoden för butiken. Vänligen försök igen.',
				placement: 'bottomRight',
				duration: 10,
			});
		},
		{ enabled: stores.length > 0 }
	);

	const displayedDiscounts = discounts.filter(
		(discount) =>
			discount.text.toLowerCase().includes(searchTerm.toLowerCase()) &&
			(selectedStore === -1 || discount.storeIds.includes(selectedStore))
	);

	return (
		<PageContainer
			title="Rabattkoder"
			extra={
				stores.length > 0 ? (
					<Button onClick={() => navigate('create')} type="primary" icon={<PlusOutlined />}>
						Skapa rabattkod
					</Button>
				) : (
					<></>
				)
			}
		>
			<div className={styles.actionBar}>
				<Search
					value={searchTerm}
					onChange={(e) => setSearchterm(e.target.value)}
					size="large"
					placeholder="Sök efter beskrivning"
					style={{ width: 400 }}
				/>
				{stores.length > 1 && (
					<>
						<Separator />
						<StoreSelector all={isAdmin} stores={stores} selectedStore={selectedStore} onChange={setSelectedStore} />
					</>
				)}
			</div>
			<Divider />
			<Table
				loading={isFetching}
				rowKey={(discount) => discount.id}
				locale={{
					emptyText: 'Inga rabattkoder',
					triggerDesc: 'Tryck för att sortera i fallande ordning',
					triggerAsc: 'Tryck för att sortera i stigande ordning',
					cancelSort: 'Tryck för att nollställa sortering',
				}}
				pagination={{ position: ['bottomRight'], defaultPageSize: 50 }}
				scroll={{ x: '100%' }}
				size="middle"
				columns={columns}
				dataSource={displayedDiscounts}
			/>
		</PageContainer>
	);
};

export default DiscountsScreen;
