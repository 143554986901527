import { Button, Card, Divider, Form, Input, Space, Select, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import StoreContext from '../state/Store';
import styles from './styles/CreateDiscountScreen.module.scss';
import { useNavigate } from 'react-router-dom';
import r from '../http';
import Discount from '../models/Discount';
import DiscountContext from '../state/Discount';
import PageContainer from '../components/PageContainer';
import FormImage from '../components/form/Image';

const CreateDiscountScreen = () => {
	const { addDiscount } = useContext(DiscountContext);
	const { stores } = useContext(StoreContext);
	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [form] = Form.useForm();

	function selectAllStores() {
		form.setFieldValue(
			'stores',
			stores.map((store) => store.id)
		);
	}

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 14 },
	};

	const submit = async (form: any) => {
		const body = new FormData();
		body.append('text', form.text);
		if (form.image[0].originFileObj) {
			body.append('image', form.image[0].originFileObj);
		}

		form.stores.forEach((storeId: any) => {
			body.append('storeIds[]', storeId);
		});

		try {
			setIsSubmitting(true);
			const response = await r.post<Discount>('/admin/discounts', body);
			addDiscount(response.data);

			notification.success({
				message: 'Rabattkod skapad!',
				placement: 'bottomRight',
				duration: 5,
			});

			navigate(-1);
		} catch (e) {
			notification.error({
				message: 'Något gick fel',
				placement: 'bottomRight',
				duration: 5,
			});
		}
		setIsSubmitting(false);
	};

	return (
		<PageContainer back title="Skapa rabattkod">
			<Form {...layout} labelAlign="left" form={form} size="large" onFinish={submit}>
				<Card>
					<Form.Item
						extra={
							<Button style={{ marginTop: 10 }} onClick={selectAllStores}>
								Välj alla butiker
							</Button>
						}
						label="Butik"
						name="stores"
						rules={[{ required: true, message: 'Vänligen välj minst en butik' }]}
					>
						<Select
							mode="multiple"
							placeholder="Butiker"
							allowClear
							style={{ width: '100%' }}
							optionFilterProp="label"
							options={stores.map((store) => ({
								value: store.id,
								label: store.name,
							}))}
						/>
					</Form.Item>
					<Divider />
					<FormImage
						label="QR-kod"
						name="image"
						rules={[{ required: true, message: 'Vänligen ladda upp en QR-kod' }]}
					/>
					<Divider />
					<Form.Item label="Beskrivning" name="text" rules={[{ required: true, message: 'Vänligen ange beskrivning' }]}>
						<Input placeholder="Beskrivning" />
					</Form.Item>
				</Card>
				<div className={styles.actions}>
					<Form.Item>
						<Space size="middle">
							<Button onClick={() => navigate(-1)} type="text">
								Avbryt
							</Button>
							<Button icon={<PlusOutlined />} type="primary" htmlType="submit" loading={isSubmitting}>
								Skapa rabattkod
							</Button>
						</Space>
					</Form.Item>
				</div>
			</Form>
		</PageContainer>
	);
};

export default CreateDiscountScreen;
